import { useActiveMetamask } from "../hooks/useMetamaskConnect";

function Navbar() {
  const { account } = useActiveMetamask();

  return (
    <nav className="bg-class py-5 px-5">
      <div className="flex justify-between items-center">
        <div className="uppercase text-white font-bold text-xl">
          class redeem
        </div>
        {account && (
          <div className="text-white">
            {account.substring(0, 6)}...
            {account.substring(account.length - 4, account.length)}
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
