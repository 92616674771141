import { ethers } from "ethers";
import { useEffect, useState } from "react";

export function useActiveMetamask() {
  const [account, setAccount] = useState<string | undefined>();
  const [provider, setProvider] = useState<
    ethers.providers.Web3Provider | undefined
  >();

  useEffect(() => {
    const ethereum = (window as any).ethereum as any;

    const provider = new ethers.providers.Web3Provider(ethereum);

    setProvider(provider);

    provider.send("eth_accounts", []).then((accounts: string[]) => {
      setAccount(accounts.length > 0 ? accounts[0] : undefined);
    });

    ethereum.on("accountsChanged", function (accounts: string[]) {
      setAccount(accounts.length > 0 ? accounts[0] : undefined);
    });
  }, []);

  return {
    account,
    provider,
  };
}

export function useConnectWallet() {
  const requestConnect = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(
        (window as any).ethereum
      );
      await provider.send("eth_requestAccounts", []);
    } catch (err) {
      console.log(err);
    }
  };

  return {
    requestConnect,
  };
}
