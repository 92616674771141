import axios from "axios";
import { useState } from "react";
import {
  useConnectWallet,
  useActiveMetamask,
} from "../hooks/useMetamaskConnect";
import MetamaskLogo from "../assets/metamask.png";

function Home() {
  const { requestConnect } = useConnectWallet();
  const { account } = useActiveMetamask();

  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [code, setCode] = useState("");
  const [isLoad, setIsLoad] = useState(false);

  const sendRedeemCode = async () => {
    try {
      if (!account) return;
      setIsLoad(true);
      setIsError(false);
      setIsSuccess(false);
      await axios.post(process.env.REACT_APP_API_REDEEM_URL as string, {
        wallet: account,
        code: code,
      });
      setIsLoad(false);
      setIsSuccess(true);
    } catch (err) {
      // console.error(err);
      setIsLoad(false);
      setIsError(true);
    }
  };

  return (
    <div className="h-screen relative bg-class">
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        {account && !isSuccess && (
          <div className="w-80">
            <form
              onSubmit={(event) => {
                event.preventDefault();
                sendRedeemCode();
              }}
              className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
            >
              <div className="mb-4">
                <label className="block text-gray-700 text-2xl font-bold mb-10">
                  Enter the Code
                </label>
                <div>Wallet: {account.substring(0, 14)}...</div>
                <input
                  disabled={isLoad}
                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-class"
                  id="username"
                  type="text"
                  placeholder="Code"
                  onChange={(event) => {
                    setCode(event.target.value);
                  }}
                />
              </div>
              <div>
                <button
                  className="bg-class block w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Redeem
                </button>
              </div>
              <div
                className={`text-red-500 mt-5 text-sm ${
                  isError ? "visible" : "invisible"
                }`}
              >
                Code is invalid or Code has already used
              </div>
            </form>
          </div>
        )}
        {account && isSuccess && (
          <div className="text-green-500 text-xl text-center font-bold">
            Code `{code}` Redeem Success!
          </div>
        )}
        {!account && (
          <button
            onClick={requestConnect}
            className="bg-metamask hover:bg-metamask-bold text-white font-bold py-2 px-4 rounded-full flex justify-center items-center gap-3"
          >
            <div>
              <img width="25px" src={MetamaskLogo} alt="logo" />
            </div>
            <div>Connect Metamask</div>
          </button>
        )}
      </div>
    </div>
  );
}

export default Home;
